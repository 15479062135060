import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {DepartmentModel, DepartmentsModel} from "../_models/departments.model";
import {DepartmentService} from "../_services/departments.service";
import {AuthAllowAccess, AuthDisallowAccess} from "../../auth/_actions/auth.actions";

import {
  ClearTasksFromDepartment,
  InitTasksFromDepartment,
  LoadDepartments,
  LoadNextTasksFromDepartment,
  LoadPrevTasksFromDepartment,
  UpdatePageFromDepartment
} from "../_actions/departments.actions";
import {InitReportsAfterDepartmentsLoad} from "../../reports/_actions/reports.actions";
import {UserState} from "../../user/_state/user.state";


export const _DepartmentsDefault: DepartmentModel[] = [];

@State<DepartmentsModel>({
  name: 'WKL_DEPARTMENTS',
  defaults: _DepartmentsDefault,
})
@Injectable()
export class DepartmentsState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private store: Store,private departmentService: DepartmentService) {}

  ngxsOnInit(ctx?: StateContext<any>): any {}

  ngxsOnChanges(change: NgxsSimpleChange<DepartmentsModel>): void {}

  ngxsAfterBootstrap(ctx?: StateContext<DepartmentsModel>): void {}

  @Selector()
  static selectDepartments( state: DepartmentModel[] ) {
    return state;
  }

  static selectTasksByDepartmentId( _id: number ) {
    return createSelector([ DepartmentsState.selectDepartments ], ( _departments: any) => {
      return _departments.find(x => x._id === _id).tasks;
    });
  }

  static selectDepartmentById( _id: number ) {
    return createSelector([ DepartmentsState.selectDepartments ], ( _departments: any) => {
      return _departments.find(x => x._id === _id);
    });
  }


  @Action(AuthAllowAccess)
  authAllowAccess(ctx: StateContext<DepartmentsModel>) {
    ctx.dispatch(new LoadDepartments());
  }

  @Action(AuthDisallowAccess)
  authDisallowAccess(ctx: StateContext<DepartmentsModel>) {
    ctx.setState(_DepartmentsDefault);
  }

  @Action(LoadDepartments)
  async loadDepartments(ctx: StateContext<DepartmentModel[]>) {
    let _departments_state = ctx.getState();
    let _departments = await this.departmentService.getDepartments();
    if(_departments) {
      if(JSON.stringify(_departments_state) !== JSON.stringify(_departments)) {
        ctx.setState(_departments);
        ctx.dispatch(new InitReportsAfterDepartmentsLoad());
      }
    }
  }

  @Action(InitTasksFromDepartment)
  async initTasksFromDepartment(ctx: StateContext<DepartmentModel[]>, payload) {
    const _state = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState.selectUser);
    const _department = _state.find((_dep) => _dep._id == _state_user.settings.department._id);
    let _tasks = await  this.departmentService.loadDepartmentTasks(_department._id, _department.page).toPromise();
    if(_tasks) {
      const _departments_state_with_tasks = _state.reduce((departments: DepartmentModel[], department) => {
        let _deps = {...department};
        if(_deps._id == _department._id) {
          _deps.tasks = _tasks;
        }
        departments.push(_deps);
        return departments;
      }, []);
      ctx.setState(_departments_state_with_tasks);
    }
  }

  @Action(ClearTasksFromDepartment)
  async clearTasksFromDepartment(ctx: StateContext<DepartmentModel[]>, payload) {
    const _state = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState.selectUser);
    const _department = _state.find((_dep) => _dep._id == _state_user.settings.department._id);
    const _departments_state_without_tasks = _state.reduce((departments: DepartmentModel[], department) => {
        let _deps = {...department};
        if(_deps._id == _department._id) {
          delete _deps.tasks;
        }
        departments.push(_deps);
        return departments;
      }, []);
    ctx.setState(_departments_state_without_tasks);

  }

  @Action(UpdatePageFromDepartment)
  async updatePageFromDepartment(ctx: StateContext<DepartmentModel[]>, payload) {
    const _departments_state = ctx.getState();
    const _user_department = this.store.selectSnapshot(UserState.selectDepartment);
    const _departments_state_with_tasks = _departments_state.reduce((departments: DepartmentModel[], department) => {
      let _deps = {...department};
      if(_deps._id == _user_department._id) {
        _deps.page = payload.page;
      }
      departments.push(_deps);
      return departments;
    }, []);
    ctx.setState(_departments_state_with_tasks);
  }

  @Action(LoadPrevTasksFromDepartment)
  async loadPrevTasksFromDepartment(ctx: StateContext<DepartmentModel[]>, payload) {

    const _departments_state = ctx.getState();
    const _user_department = this.store.selectSnapshot(UserState.selectDepartment);
    const _tasks_new_page = payload.page;

    let _tasks = await  this.departmentService.loadDepartmentTasks(_user_department._id, _tasks_new_page).toPromise();

    const _departments_state_with_tasks = _departments_state.reduce((departments: DepartmentModel[], department) => {
      let _deps = {...department};
      if(_deps._id == _user_department._id) {
        _deps.tasks = [..._tasks, ..._deps.tasks];
      }
      departments.push(_deps);
      return departments;
    }, []);
    ctx.setState(_departments_state_with_tasks);
  }

  @Action(LoadNextTasksFromDepartment)
  async loadNextTasksFromDepartment(ctx: StateContext<DepartmentModel[]>, payload) {
    const _departments_state = ctx.getState();
    const _user_department = this.store.selectSnapshot(UserState.selectDepartment);
    const _tasks_new_page = payload.page;

    let _tasks = await  this.departmentService.loadDepartmentTasks(_user_department._id, _tasks_new_page).toPromise();
    if(_tasks) {
      const _departments_state_with_tasks = _departments_state.reduce((departments: DepartmentModel[], department) => {
        let _deps = {...department};
        if(_deps._id == _user_department._id) {
          _deps.tasks = [..._deps.tasks, ..._tasks];
        }
        departments.push(_deps);
        return departments;
      }, []);

      //ctx.dispatch(new UpdateDepartmentsProgressReport(_user_department._id, _tasks_new_page));
      ctx.setState(_departments_state_with_tasks);
    }

  }
}
