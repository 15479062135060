import {ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {FlexibleConnectedPositionStrategy, Overlay, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {BhTooltipComponent} from "../_ui/components/bh-tooltip/bh-tooltip.component";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";


@Directive({
  selector: '[tooltip]',
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'tooltips', alias: 'tooltips'  } }],
})
export class ToolTipsDirective implements OnInit, OnDestroy {

  @Input() showToolTip = true;
  @Input() title: string;
  @Input() description: string;
  @Input() position = 'below';
  @Input() contentTemplate: TemplateRef<any>;

  private _overlayRef: OverlayRef;

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    if ( !this.showToolTip ) { return; }

    let positionStrategy: FlexibleConnectedPositionStrategy;

    switch ( this.position ) {
      case 'above':
        positionStrategy = this._overlayPositionBuilder
          .flexibleConnectedTo(this._elementRef)
          .withPositions([
            {
              originX: 'center',
              originY: 'top',
              overlayX: 'center',
              overlayY: 'bottom',
              offsetY: -20,
            },
          ]);
        break;
      case 'below':
        positionStrategy = this._overlayPositionBuilder
          .flexibleConnectedTo(this._elementRef)
          .withPositions([
            {
              originX: 'center',
              originY: 'bottom',
              overlayX: 'center',
              overlayY: 'top',
              offsetY: 5,
            },
          ]);
        break;
      case 'left':
        positionStrategy = this._overlayPositionBuilder
          .flexibleConnectedTo(this._elementRef)
          .withPositions([
            {
              originX: 'start',
              originY: 'center',
              overlayX: 'end',
              overlayY: 'center',
              offsetX: -5,
            },
          ]);
        break;
      case 'right':
        positionStrategy = this._overlayPositionBuilder
          .flexibleConnectedTo(this._elementRef)
          .withPositions([
            {
              originX: 'end',
              originY: 'center',
              overlayX: 'start',
              overlayY: 'center',
              offsetX: 5,
            },
          ]);
        break;
      default:
        positionStrategy = this._overlayPositionBuilder
          .flexibleConnectedTo(this._elementRef)
          .withPositions([
            {
              originX: 'center',
              originY: 'top',
              overlayX: 'center',
              overlayY: 'bottom',
              offsetY: -5,
            },
          ]);
    }

    this._overlayRef = this._overlay.create({ positionStrategy });
  }

  ngOnDestroy(): void {
    this.closeToolTip();
  }

  @HostListener('mouseenter')
  show(): void {
    if ( this._overlayRef && !this._overlayRef.hasAttached() ) {
      const tooltipRef: ComponentRef<BhTooltipComponent> = this._overlayRef.attach(new ComponentPortal(BhTooltipComponent));
      tooltipRef.instance.title = this.title;
      tooltipRef.instance.description = this.description;
      tooltipRef.instance.contentTemplate = this.contentTemplate;
      tooltipRef.instance.position = this.position;
    }
  }

  @HostListener('mouseleave')
  hide(): void {
    this.closeToolTip();
  }

  @HostListener('click')
  hideDisable(): void {
    this.closeToolTip();
  }

  @HostListener('-touchstart')
  touchstart(): void {
    setTimeout(() => {
      this.hide();
    }, 800);
  }

  private closeToolTip(): void {
    if ( this._overlayRef ) {
      this._overlayRef.detach();
    }
  }

}
