import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BhTooltipComponent} from "./bh-tooltip.component";
import {OverlayModule} from "@angular/cdk/overlay";
import {ToolTipsDirective} from "../../../_directives/tooltip.directive";


@NgModule({
  declarations: [BhTooltipComponent, ToolTipsDirective],
  exports: [BhTooltipComponent, ToolTipsDirective],
  imports: [
    CommonModule,
    OverlayModule
  ]
})
export class BhTooltipModule { }
