import {Injectable} from '@angular/core';
import {CanMatch, Route, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MatchPublicPageWorksheetGeneratorsItemsGuard implements CanMatch {

  constructor() {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let _assess_type = ["word-search", "syllable-replacement", "word-composition", "word-matching", "sound-substitution", "sentences", "letter-placement", "word-segmentation", "addition", "subtraction", "multiplication", "division", "measurement", "time", "comparison", "problems", "letters", "roman-numerals", "arabic-numerals", "words", "syllables", "objects", "shapes", "coloring-pages", "find-the-way", "whats-wrong", "identify-shapes", "find-the-pair", "puzzles", "patterns", "find-the-shadow", "logical-connections"].includes(segments[segments.length-1].path);
    let _access_category = ['reading', 'math', 'tracing', 'logic'].includes(segments[segments.length-2].path);

    return _assess_type && _access_category;
  }

}
