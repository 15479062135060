import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {BlogModel} from "../_models/blog.model";
import {InitBlogPosts} from "../_actions/blog.actions";
import {BlogService} from "../_services/blog.service";


export const _BlogDefault: BlogModel = {
  posts: [
    {
      "_id" : "65a9210089a5110f19d5b23f",
      "title" : "Words that Start With T - Expand Your Vocabulary",
      "excerpt" : "Studies show that the words toddlers learn and use lead to school success in the future. In fact, developing an extensive vocabulary at the age of two, such as learning words that start with T, can help a child succeed in different areas at kindergarten level like math, reading, and behavior. So, if your little ",
      "slug" : "words-that-start-with-t-expand-your-vocabulary",
      "date" : "29 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_t_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "words_that_start_with_t_1_img",
        "url" : "/assets/images/uploads/words_that_start_with_t_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 9,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 19
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b240",
      "title" : "Words that Start With S - Expand Your Vocabulary",
      "excerpt" : "It may be time to start introducing words that start with S if your child has already learned vocabulary words beginning with the first 18 letters. The consonant S is the nineteenth letter of the English alphabet and is present in many words of the English language. There are many words that start with S, ",
      "slug" : "words-that-start-with-s-expand-your-vocabulary",
      "date" : "28 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_s_1.svg",
        "width" : 252,
        "height" : 73,
        "alt" : "words_that_start_with_s_img_1",
        "url" : "/assets/images/uploads/words_that_start_with_s_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 9,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 20
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b241",
      "title" : "Words that Start With R - Expand Your Vocabulary",
      "excerpt" : "If your child has mastered their ABCs and vocabulary words beginning with letters A to Q, it may be time to introduce them to words that start with R. R is the eighteenth letter of the English alphabet. By the time you start teaching children words that start with R, it’s likely that they’ve already ",
      "slug" : "words-that-start-with-r-expand-your-vocabulary",
      "date" : "27 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_r_1.svg",
        "width" : 252,
        "height" : 73,
        "alt" : "words_that_start_with_r_img_1",
        "url" : "/assets/images/uploads/words_that_start_with_r_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 10,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 21
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b242",
      "title" : "Words that Start With P - Expand Your Vocabulary",
      "excerpt" : "P is the sixteenth letter of the English alphabet, so your child should already have developed a good vocabulary before you introduce them to P words. If they have already mastered their ABCs and words beginning with each of the first fifteen letters, it’s time they start with words that start with P. Even if ",
      "slug" : "words-that-start-with-p-expand-your-vocabulary",
      "date" : "26 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_p_1a.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "words_that_start_with_p_1a",
        "url" : "/assets/images/uploads/words_that_start_with_p_1a.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 10,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 22
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b243",
      "title" : "Words that Start With N - Expand Your Vocabulary",
      "excerpt" : "If your child has mastered their ABCs and vocabulary words that start with the first 13 letters, it may be time to introduce them to words that start with N. By the time you start teaching them N words, your child is likely to have built a good vocabulary. However, you cannot put a cap ",
      "slug" : "words-that-start-with-n-expand-your-vocabulary",
      "date" : "25 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_n_expand-_your_vocabulary_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "words_that_start_with_n_expand _your_vocabulary_1",
        "url" : "/assets/images/uploads/words_that_start_with_n_expand-_your_vocabulary_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 9,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 23
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b244",
      "title" : "Words that Start With M - Expand Your Vocabulary",
      "excerpt" : "The consonant M is the thirteenth letter of the English alphabet. This suggests that your child is likely to have built a good vocabulary by the time they start learning words that start with M. However, you can’t put a limit on what makes a ‘good vocabulary.’ After all, a vast vocabulary can prove helpful ",
      "slug" : "words-that-start-with-m-expand-your-vocabulary",
      "date" : "24 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_m_1.svg",
        "width" : 252,
        "height" : 73,
        "alt" : "words_that_start_with_m_img_1",
        "url" : "/assets/images/uploads/words_that_start_with_m_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 10,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 24
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b245",
      "title" : "Words that Start With L - Expand Your Vocabulary",
      "excerpt" : "L is the twelfth letter in the English alphabet, so by the time you teach your child words that start with L, they should already know vocabulary words that start from letters A to K. If your child has mastered their ABCs and vocabulary words beginning from the first 11 letters, it’s about time that ",
      "slug" : "words-that-start-with-l-expand-your-vocabulary",
      "date" : "23 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_L_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "words_that_start_with_L_1_img",
        "url" : "/assets/images/uploads/words_that_start_with_L_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 9,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 25
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b246",
      "title" : "Words that Start With K - Expand Your Vocabulary",
      "excerpt" : "By the time you start teaching words that start with K, the child is likely to have built quite a good vocabulary already. But even so, you can’t put a cap on an expansive vocabulary, as words play such a huge part in our lives. Therefore, your child must build a vocabulary comprising many words ",
      "slug" : "words-that-start-with-k-expand-your-vocabulary",
      "date" : "22 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_k_img_1.svg",
        "width" : 252,
        "height" : 73,
        "alt" : "words_that_start_with_k_img_1",
        "url" : "/assets/images/uploads/words_that_start_with_k_img_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 9,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 26
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b247",
      "title" : "Words that Start With F - Expand Your Vocabulary",
      "excerpt" : "If you think your child has mastered the alphabet and words starting with the letters A to E, it might be time to start introducing words that start with F. Of course, it is important to remember that jumping from one letter to another can be stressful for the child. You may not realize it, ",
      "slug" : "words-that-start-with-f",
      "date" : "04 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_f_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "words_that_start_with_f_1",
        "url" : "/assets/images/uploads/words_that_start_with_f_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 10,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 27
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b248",
      "title" : "Words that Start With E - Expand Your Vocabulary",
      "excerpt" : "Is it time for your child to start learning words that start with E? The letter E is the fifth letter of the English alphabet and also happens to be a vowel. The latter property suggests that it is a commonly used letter in the English language. Not only will you find this letter a ",
      "slug" : "words-that-start-with-e",
      "date" : "03 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_E_1.svg",
        "width" : 252,
        "height" : 73,
        "alt" : "words_that_start_with_E_img_1",
        "url" : "/assets/images/uploads/words_that_start_with_E_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 11,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 28
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b249",
      "title" : "Words that Start With D - Expand Your Vocabulary",
      "excerpt" : "Have you successfully taught your child different words starting with A, B, and C? If so, then it’s about time that you start introducing words that start with D. Of course, you need to make sure you’re not putting a lot of pressure on the child. When introducing new word’s to your child’s vocabulary, make ",
      "slug" : "words-that-start-with-d",
      "date" : "02 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_d_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "words_that_start_with_d_1_img",
        "url" : "/assets/images/uploads/words_that_start_with_d_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 11,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 29
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b24a",
      "title" : "Words that Start With B - Expand Your Vocabulary",
      "excerpt" : "If you’re looking to teach your child words that start with B, check if your child recognizes the letter first. It is best to teach the letters in order, which makes B the second one, but you should always start off by helping your child recognize all of the 26 letters. This way, they learn ",
      "slug" : "words-that-start-with-b",
      "date" : "01 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_b_expand_your_vocabulary_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "words_that_start_with_b_expand_your_vocabulary_1",
        "url" : "/assets/images/uploads/words_that_start_with_b_expand_your_vocabulary_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 10,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 30
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b24b",
      "title" : "Words that Start With Q - Expand Your Vocabulary",
      "excerpt" : "By the time you’re teaching children words that start with Q, they already have a good vocabulary. They’ll know plenty of words that begin with letters like A and G. Even so; a comprehensive vocabulary plays such an important role in language development that it’s best to keep on teaching new words. Over time, they ",
      "slug" : "words-that-start-with-q",
      "date" : "24 December 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_q_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "words_that_start_with_q_1",
        "url" : "/assets/images/uploads/words_that_start_with_q_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 9,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 31
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b24c",
      "title" : "Words that Start With J - Expand Your Vocabulary",
      "excerpt" : "If you’re looking to teach your kids the tenth letter of the English alphabet and words that start with J, you are in the right place. While the letter J is an important part of the English alphabet, it can be hard for little readers to learn and remember. In case you have already tried ",
      "slug" : "words-that-start-with-j",
      "date" : "23 December 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_j_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "words_that_start_with_j_1",
        "url" : "/assets/images/uploads/words_that_start_with_j_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 8,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 32
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b24d",
      "title" : "Words that Start With G - Expand Your Vocabulary",
      "excerpt" : "G is the seventh letter of the English alphabet and is an interesting one for several reasons. For starters, the letter is one of the most commonly used consonants. You can easily find numerous words that start with G or contain it in the middle. Because it’s a letter that you can find in every ",
      "slug" : "words-that-start-with-g",
      "date" : "23 December 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_g_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "words_that_start_with_g_1",
        "url" : "/assets/images/uploads/words_that_start_with_g_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 7,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 33
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b24e",
      "title" : "Words that Start With C - Expand Your Vocabulary",
      "excerpt" : "By the time you begin teaching your child words that start with C, they must have already learned some A and B letter words. This is great because the experience will help your child effectively learn new words beginning with different letters. C is the third letter out of the 26 letters of the English ",
      "slug" : "words-that-start-with-c",
      "date" : "22 December 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_c_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "words_that_start_with_c_1_img",
        "url" : "/assets/images/uploads/words_that_start_with_c_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 10,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 34
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b24f",
      "title" : "Words that Start With A - Expand Your Vocabulary",
      "excerpt" : "If your child is now big enough for you to introduce the alphabet, we are here to help you out on the challenge. While it is important to learn to write and pronounce the letter ‘A,’ introducing children to words that start with A is what is most beneficial. Having a rich vocabulary is neceаssary ",
      "slug" : "words-that-start-with-a",
      "date" : "22 December 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_a_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "words_that_start_with_a_1",
        "url" : "/assets/images/uploads/words_that_start_with_a_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 9,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 35
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b250",
      "title" : "Adjectives Starting With X - 94 Words to Boost Your Vocabulary",
      "excerpt" : "What are adjectives? Have you ever read sentences with adjectives like big, round eyes tattered pages lovely dress Now imagine the same nouns in complete sentences without the describing words. The text wouldn’t be as interesting, and more importantly, you will feel the need for specific details. This is why we have adjectives. Adjectives are ",
      "slug" : "adjectives-starting-with-x-94-words-to-boost-your-vocabulary",
      "date" : "11 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/adjectives_starting_with_x_94_words_to_boost_your_vocabulary_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "adjectives_starting_with_x_94_words_to_boost_your_vocabulary_1",
        "url" : "/assets/images/uploads/adjectives_starting_with_x_94_words_to_boost_your_vocabulary_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 2,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 41
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Adjectives",
          "slug" : "adjectives",
          "path" : "blog/adjectives"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b251",
      "title" : "Adjectives Starting With R - 455 Words to Boost Your Vocabulary",
      "excerpt" : "What are adjectives? Adjectives are words that restrict or add to the sense of a noun or pronoun. They are an exciting part of speech as they can give any text imagery by describing the nouns in it. These are the same set of words that you use to tell your friend about the melodious ",
      "slug" : "adjectives-starting-with-r-455-words-to-boost-your-vocabulary",
      "date" : "08 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/adjectives_starting_with_r_455_words_to_boost_your_vocabulary_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "adjectives_starting_with_r_455_words_to_boost_your_vocabulary_1",
        "url" : "/assets/images/uploads/adjectives_starting_with_r_455_words_to_boost_your_vocabulary_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 2,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 46
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Adjectives",
          "slug" : "adjectives",
          "path" : "blog/adjectives"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b252",
      "title" : "Adjectives Starting With C - 372 Words to Boost Your Vocabulary",
      "excerpt" : "What are adjectives? Adjectives are descriptive words, with which we can quantify and identify individuals and unique things. We rely on parts of the speech in the English Language to refine and give structure to our sentences. We can place sentences before our nouns or pronouns or even after them, depending on your sentence type. ",
      "slug" : "adjectives-starting-with-c-372-words-to-boost-your-vocabulary",
      "date" : "06 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/adjectives_starting_with_c_372_words_to_boost_your_vocabulary_1_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "adjectives_starting_with_c_372_words_to_boost_your_vocabulary_1_1",
        "url" : "/assets/images/uploads/adjectives_starting_with_c_372_words_to_boost_your_vocabulary_1_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 3,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 48
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Adjectives",
          "slug" : "adjectives",
          "path" : "blog/adjectives"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b253",
      "title" : "Adjectives Starting With S - 632 Words to Boost Your Vocabulary",
      "excerpt" : "What are adjectives? There are some words that we use to describe a thing or person, or even our feelings and thoughts. Grammarians refer to such words as adjectives. Adjectives are defined or learned as words that modify or describe a noun. When you need to add detailed sense to describe anything, you make use ",
      "slug" : "adjectives-starting-with-s-632-words-to-boost-your-vocabulary",
      "date" : "06 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/adjectives_starting_with_s_632_words_to_boost_your_vocabulary_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "adjectives_starting_with_s_632_words_to_boost_your_vocabulary_1",
        "url" : "/assets/images/uploads/adjectives_starting_with_s_632_words_to_boost_your_vocabulary_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 7,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 49
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Adjectives",
          "slug" : "adjectives",
          "path" : "blog/adjectives"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b254",
      "title" : "Adjectives Starting With I - 497 Words to Boost Your Vocabulary",
      "excerpt" : "When you’re learning grammar concepts, the most important tools you will meet are the parts of speech. Adjectives are one of the most essential language tools because they bring our text to life with imagery and vivid details. The majority of English language sentences need nouns and pronouns to sound complete. However, they alone are ",
      "slug" : "adjectives-starting-with-i-497-words-to-boost-your-vocabulary",
      "date" : "03 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/adjectives_starting_with_i_497_words_to_boost_your_vocabulary_1a.svg",
        "width" : 252,
        "height" : 1,
        "alt" : "Adjectives starting with i 497 words img1",
        "url" : "/assets/images/uploads/adjectives_starting_with_i_497_words_to_boost_your_vocabulary_1a.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 3,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 54
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Adjectives",
          "slug" : "adjectives",
          "path" : "blog/adjectives"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b255",
      "title" : "Adjectives Starting With Q - 195 Words to Boost Your Vocabulary",
      "excerpt" : "Adjectives are descriptive words that describe the quality, state, and attributes of a noun or pronoun. We use these words to add structure, depth, and interesting angles to our text. If we did not have adjectives, we’d all only read dull, uninteresting, and flat sentences. They would be no details to excite our imagination or ",
      "slug" : "adjectives-starting-with-q-195-words-to-boost-your-vocabulary",
      "date" : "03 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/adjectives_starting_with_q_195_words_to_boost_your_vocabulary_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "adjectives_starting_with_q_195_words_to_boost_your_vocabulary_1",
        "url" : "/assets/images/uploads/adjectives_starting_with_q_195_words_to_boost_your_vocabulary_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 3,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 55
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Adjectives",
          "slug" : "adjectives",
          "path" : "blog/adjectives"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b256",
      "title" : "Adjectives Starting With E - 211 Words to Boost Your Vocabulary",
      "excerpt" : "What are adjectives? Do you wonder what adjectives are and how you can use them in your written text and speech? Let us help you learn about adjectives in this article. Adjectives are a part of the speech that is comprised of descriptive words. We use these to describe a thing, place, or person. Adjectives ",
      "slug" : "adjectives-starting-with-e-211-words-to-boost-your-vocabulary",
      "date" : "02 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/adjectives_starting_with_e_lets_boost_our_vocabulary_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "adjectives_starting_with_e_let’s_boost_our_vocabulary_1",
        "url" : "/assets/images/uploads/adjectives_starting_with_e_lets_boost_our_vocabulary_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 2,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 56
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Adjectives",
          "slug" : "adjectives",
          "path" : "blog/adjectives"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b257",
      "title" : "Adjectives Starting With J - 143 Words to Boost Your Vocabulary",
      "excerpt" : "Have you ever imagined how sentences would be like without descriptive words? Nobody would enjoy reading then. This is why we love adjectives so much because they provide us with all the exciting descriptive words we need. The parts of the speech are essential language tools. Do you know why we call them that? It ",
      "slug" : "adjectives-starting-with-j-143-words-to-boost-your-vocabulary",
      "date" : "01 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/adjectives_starting_with_j_143_words_to_boost_your_vocabulary_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "adjectives_starting_with_j_143_words_to_boost_your_vocabulary_1",
        "url" : "/assets/images/uploads/adjectives_starting_with_j_143_words_to_boost_your_vocabulary_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 3,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 58
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Adjectives",
          "slug" : "adjectives",
          "path" : "blog/adjectives"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b258",
      "title" : "Adjectives Starting With P - 588 Words to Boost Your Vocabulary",
      "excerpt" : "Adjectives are an integral part of the English language. Can you guess why? It is because they bring life, vitality, interest, and information to sentences. Our speech and writing would sound rather dull and fail to spark our imagination if we do not include details in it. Take a look at an example: Without adjectives ",
      "slug" : "adjectives-starting-with-p-588-words-to-boost-your-vocabulary",
      "date" : "24 October 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/adjectives_starting_with_p-08.svg",
        "width" : 252,
        "height" : 73,
        "alt" : "adjectives_starting_with_p",
        "url" : "/assets/images/uploads/adjectives_starting_with_p-08.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 3,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 64
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Adjectives",
          "slug" : "adjectives",
          "path" : "blog/adjectives"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b259",
      "title" : "Adjectives Starting With N - 333 Words to Boost Your Vocabulary",
      "excerpt" : "Adjectives are a list of words that help bring a text to life, with vivid details and imagery! Have you ever imagined how reading would be if there were no descriptive words to boost a text? Imagine if we had no words to tell you how our meal tasted, how an outfit looked, how an ",
      "slug" : "adjectives-starting-with-n-333-words-to-boost-your-vocabulary",
      "date" : "23 October 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/adjectives_starting_with_n-23.svg",
        "width" : 252,
        "height" : 73,
        "alt" : "adjectives_starting_with_n",
        "url" : "/assets/images/uploads/adjectives_starting_with_n-23.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 3,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 65
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Adjectives",
          "slug" : "adjectives",
          "path" : "blog/adjectives"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b25a",
      "title" : "Adjectives Starting With K - 129 Words to Boost Your Vocabulary",
      "excerpt" : "Adjectives are a part of the speech and are very useful. They are descriptive words that help us make our nouns and pronouns more exciting and interesting for the readers. Moreover, adjectives help us provide the vital information our reader would need regarding a noun or pronoun. Since grammar plays an integral part in our ",
      "slug" : "adjectives-starting-with-k-129-words-to-boost-your-vocabulary",
      "date" : "21 October 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/adjectives_starting_with_k_275_words_to_boost_your_vocabulary_1.svg",
        "width" : 1,
        "height" : 1,
        "alt" : "adjectives_starting_with_k_275_words_to_boost_your_vocabulary_1",
        "url" : "/assets/images/uploads/adjectives_starting_with_k_275_words_to_boost_your_vocabulary_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 2,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 66
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Adjectives",
          "slug" : "adjectives",
          "path" : "blog/adjectives"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b25b",
      "title" : "Adjectives Starting With A - 240 Words to Boost Your Vocabulary",
      "excerpt" : "Did you know that our words and ideas are raw materials that need tools to get into shape? We call them parts speech and they’re the tools that polish and beautify our sentences. Adjectives are such powerful language tools because they make any piece of writing more interesting, detailed, and vibrant. That’s the quality of ",
      "slug" : "adjectives-starting-with-a-240-words-to-boost-your-vocabulary",
      "date" : "18 October 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/adjectives_starting_with_A-16.svg",
        "width" : 252,
        "height" : 73,
        "alt" : "adjectives_starting_with_A",
        "url" : "/assets/images/uploads/adjectives_starting_with_A-16.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 3,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 69
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Adjectives",
          "slug" : "adjectives",
          "path" : "blog/adjectives"
        }
      ]
    },
    {
      "_id" : "65a9210089a5110f19d5b25c",
      "title" : "Words that Start With H - Expand Your Vocabulary",
      "excerpt" : "It may be time to start introducing words that start with H if you think your child has mastered their ABCs and words starting with letters A to G. Of course, you must not forget that jumping from one letter to another can be quite stressful for your child. As an adult, you may not ",
      "slug" : "words-that-start-with-h-expand-your-vocabulary",
      "date" : "20 November 2023",
      "thumbnail" : {
        "src" : "/assets/images/uploads/words_that_start_with_h_1.svg",
        "width" : 252,
        "height" : 73,
        "alt" : "words_that_start_with_h_img_1",
        "url" : "/assets/images/uploads/words_that_start_with_h_1.svg"
      },
      "author" : {
        "name" : "Laura Lewis",
        "slug" : "laura_lewis",
        "avatar" : "expert_1.png"
      },
      "info" : {
        "read_time" : 10,
        "need_time" : false,
        "read_later" : "Read later",
        "index" : 105
      },
      "categories" : [
        {
          "name" : "Blog",
          "slug" : "blog",
          "path" : "blog"
        },
        {
          "name" : "K8",
          "slug" : "k8",
          "path" : "blog/k8"
        },
        {
          "name" : "Preschool",
          "slug" : "preschool",
          "path" : "blog/preschool"
        },
        {
          "name" : "Words",
          "slug" : "words",
          "path" : "blog/words"
        }
      ]
    }
  ],
};

@State<BlogModel>({
  name: 'WKL_BLOG',
  defaults: _BlogDefault,
})
@Injectable()
export class BlogState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private blogService: BlogService) {}

  ngxsAfterBootstrap(ctx: StateContext<any>): void {

  }

  ngxsOnInit(ctx?: StateContext<any>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<BlogModel>): void {}


  @Selector()
  static selectPosts( state: BlogModel) {
    return state.posts;
  }

  static selectPostBySlug( _slug: string ) {
    return createSelector([ BlogState.selectPosts ], ( _posts: any[]) => {
      return _posts.find(x => x.slug === _slug);
    });
  }


  @Action(InitBlogPosts)
  async initBlogPosts(ctx: StateContext<BlogModel>, _data) {
    const _state = ctx.getState();
    //let _posts = await this.blogService.getPosts(_data.page);
/*    let _posts = [];
      ctx.patchState({
      ..._state,
      posts: _posts
    });*/
  }

}
