import {Injectable} from '@angular/core';
import {CanMatch, Route, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {SettingsState} from "../_stores/settings/_state/settings.state";

@Injectable({
  providedIn: 'root',
})
export class NoMatchComingSoonGuard implements CanMatch {

  constructor(private store: Store) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !this.store.selectSnapshot(SettingsState.selectComingSoon);
  }

}
