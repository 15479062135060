import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BhTipsComponent} from "./bh-tips.component";
import {TranslocoModule} from "@ngneat/transloco";


@NgModule({
  declarations: [BhTipsComponent],
  exports: [BhTipsComponent],
  imports: [
    CommonModule,
    TranslocoModule
  ]
})
export class BhTipsModule { }
