import {Injectable} from '@angular/core';
import {CanMatch, Route, Router, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from "../_stores/auth/_state/auth.state";

@Injectable({
  providedIn: 'root',
})
export class MatchPublicPageGuard implements CanMatch {

  constructor(private store: Store, private router: Router) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !this.store.selectSnapshot(AuthState.selectAccess);
  }

}
