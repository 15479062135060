import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {ReviewModel, WorkbookModel, WorkbooksModel} from "../_models/workbooks.model";
import {InitWorkbooksItems, InitWorkbooksReviews} from "../_actions/workbooks.actions";
import {WorkbooksService} from "../_services/workbooks.service";
import {default as _REVIEWS} from '../../../../assets/json/_reviews.json';

export const _WorkbooksDefault: WorkbooksModel = {
  items: [
    {
      "_id": "64c20621f0ad9733e4bec95c",
      "title": "pages.workbooks.items.0.title",
      "description": "pages.workbooks.items.0.description",
      "slug": "karlyuchka_tsyfry_propysy_ta_praktyka",
      "category": "karlyuchka",
      "thumbnail": "bg_wb_0_0.webp",
      "gallery": [
        "bg_wb_0_1.webp",
        "bg_wb_0_2.webp",
        "bg_wb_0_3.webp",
        "bg_wb_0_4.webp",
        "bg_wb_0_5.webp",
        "bg_wb_0_6.webp"
      ],
      "age": "3-8",
      "series": "karlyuchka",
      "print_length": 134,
      "pub_date": "2023-07-27T02:54:21.116Z",
      "isbn": "979-8395009753",
      "weight": 15,
      "dimensions": "8.27 x 0.32 x 11.69 inches",
      "languages": "ua",
      "color": false,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 9.99,
            "ua": 365
          },
          "sellers": {
            "amazon": "https://a.co/d/iZ0Uvxb",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 9.99,
            "ua": 383
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec95d",
      "title": "pages.workbooks.items.1.title",
      "description": "pages.workbooks.items.1.description",
      "slug": "karlyuchka_tsyfry_propysy_ta_praktyka_color",
      "category": "karlyuchka",
      "thumbnail": "bg_wb_1_0.webp",
      "gallery": [
        "bg_wb_1_1.webp",
        "bg_wb_1_2.webp",
        "bg_wb_1_3.webp",
        "bg_wb_1_4.webp",
        "bg_wb_1_5.webp",
        "bg_wb_1_6.webp"
      ],
      "age": "3-8",
      "series": "karlyuchka",
      "print_length": 134,
      "pub_date": "2023-07-27T02:54:21.116Z",
      "isbn": "979-8395009753",
      "weight": 15,
      "dimensions": "8.27 x 0.32 x 11.69 inches",
      "languages": "ua",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 24.75,
            "ua": 365
          },
          "sellers": {
            "amazon": "https://a.co/d/8JEa9jS",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 24.75,
            "ua": 383
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec95e",
      "title": "pages.workbooks.items.2.title",
      "description": "pages.workbooks.items.2.description",
      "slug": "karlyuchka_numbers_tracing_and_practice",
      "category": "karlyuchka",
      "thumbnail": "bg_wb_2_0.webp",
      "gallery": [
        "bg_wb_2_1.webp",
        "bg_wb_2_2.webp",
        "bg_wb_2_3.webp",
        "bg_wb_2_4.webp",
        "bg_wb_2_5.webp",
        "bg_wb_2_6.webp"
      ],
      "age": "3-8",
      "series": "karlyuchka",
      "print_length": 134,
      "pub_date": "2023-07-27T02:54:21.116Z",
      "isbn": "979-8395754080",
      "weight": 14.6,
      "dimensions": "8.5 x 0.31 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 19.99,
            "ua": 365
          },
          "sellers": {
            "amazon": "https://a.co/d/bFwhbDp",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 19.99,
            "ua": 383
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec95f",
      "title": "pages.workbooks.items.3.title",
      "description": "pages.workbooks.items.3.description",
      "slug": "karlyuchka_math_addition_practice",
      "category": "karlyuchka",
      "thumbnail": "bg_wb_3_0.webp",
      "gallery": [
        "bg_wb_3_1.webp",
        "bg_wb_3_2.webp",
        "bg_wb_3_3.webp",
        "bg_wb_3_4.webp",
        "bg_wb_3_5.webp",
        "bg_wb_3_6.webp"
      ],
      "age": "5-7",
      "series": "karlyuchka",
      "print_length": 24,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8397245135",
      "weight": 4,
      "dimensions": "8.5 x 0.06 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 9.99,
            "ua": 99
          },
          "sellers": {
            "amazon": "https://a.co/d/6bk3SJW",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 9.99,
            "ua": 115
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec960",
      "title": "pages.workbooks.items.4.title",
      "description": "pages.workbooks.items.4.description",
      "slug": "karlyuchka_math_subtraction_practice",
      "category": "karlyuchka",
      "thumbnail": "bg_wb_4_0.webp",
      "gallery": [
        "bg_wb_4_1.webp",
        "bg_wb_4_2.webp",
        "bg_wb_4_3.webp",
        "bg_wb_4_4.webp",
        "bg_wb_4_5.webp",
        "bg_wb_4_6.webp"
      ],
      "age": "5-7",
      "series": "karlyuchka",
      "print_length": 24,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8397255769",
      "weight": 4,
      "dimensions": "8.5 x 0.06 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 9.99,
            "ua": 99
          },
          "sellers": {
            "amazon": "https://a.co/d/3Oys1EX",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 9.99,
            "ua": 115
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec961",
      "title": "pages.workbooks.items.5.title",
      "description": "pages.workbooks.items.5.description",
      "slug": "karlyuchka_math_multiplication_practice",
      "category": "karlyuchka",
      "thumbnail": "bg_wb_5_0.webp",
      "gallery": [
        "bg_wb_5_1.webp",
        "bg_wb_5_2.webp",
        "bg_wb_5_3.webp",
        "bg_wb_5_4.webp",
        "bg_wb_5_5.webp",
        "bg_wb_5_6.webp"
      ],
      "age": "5-7",
      "series": "karlyuchka",
      "print_length": 24,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8397256872",
      "weight": 4,
      "dimensions": "8.5 x 0.06 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 9.99,
            "ua": 99
          },
          "sellers": {
            "amazon": "https://a.co/d/2YvQGAN",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 9.99,
            "ua": 115
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec962",
      "title": "pages.workbooks.items.6.title",
      "description": "pages.workbooks.items.6.description",
      "slug": "karlyuchka_math_division_practice",
      "category": "karlyuchka",
      "thumbnail": "bg_wb_6_0.webp",
      "gallery": [
        "bg_wb_6_1.webp",
        "bg_wb_6_2.webp",
        "bg_wb_6_3.webp",
        "bg_wb_6_4.webp",
        "bg_wb_6_5.webp",
        "bg_wb_6_6.webp"
      ],
      "age": "5-7",
      "series": "karlyuchka",
      "print_length": 24,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8397257466",
      "weight": 4,
      "dimensions": "8.5 x 0.06 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 9.99,
            "ua": 99
          },
          "sellers": {
            "amazon": "https://a.co/d/cZn3Hp1",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 9.99,
            "ua": 115
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec963",
      "title": "pages.workbooks.items.7.title",
      "description": "pages.workbooks.items.7.description",
      "slug": "karakulya_bukvy_propysy_ta_praktyka_ukrayinska_abetka_color",
      "category": "karakulya",
      "thumbnail": "bg_wb_7_0.webp",
      "gallery": [
        "bg_wb_7_1.webp",
        "bg_wb_7_2.webp",
        "bg_wb_7_3.webp",
        "bg_wb_7_4.webp",
        "bg_wb_7_5.webp",
        "bg_wb_7_6.webp"
      ],
      "age": "3-8",
      "series": "karakulya",
      "print_length": 200,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8373144070",
      "weight": 21.6,
      "dimensions": "8.27 x 0.47 x 11.69 inches",
      "languages": "ua",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 24.61,
            "ua": 365
          },
          "sellers": {
            "amazon": "https://a.co/d/0823zFp",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 24.61,
            "ua": 383
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec964",
      "title": "pages.workbooks.items.8.title",
      "description": "pages.workbooks.items.8.description",
      "slug": "karakulya_bukvy_propysy_ta_praktyka_ukrayinska_abetka",
      "category": "karakulya",
      "thumbnail": "bg_wb_8_0.webp",
      "gallery": [
        "bg_wb_8_1.webp",
        "bg_wb_8_2.webp",
        "bg_wb_8_3.webp",
        "bg_wb_8_4.webp",
        "bg_wb_8_5.webp",
        "bg_wb_8_6.webp"
      ],
      "age": "3-8",
      "series": "karakulya",
      "print_length": 200,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8395781925",
      "weight": 21.6,
      "dimensions": "8.27 x 0.47 x 11.69 inches",
      "languages": "ua",
      "color": false,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 9.99,
            "ua": 365
          },
          "sellers": {
            "amazon": "https://a.co/d/aCWyRZe",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 9.99,
            "ua": 383
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec965",
      "title": "pages.workbooks.items.9.title",
      "description": "pages.workbooks.items.9.description",
      "slug": "karakulya_letters_tracing_and_practice_ukrainian_alphabet",
      "category": "karakulya",
      "thumbnail": "bg_wb_9_0.webp",
      "gallery": [
        "bg_wb_9_1.webp",
        "bg_wb_9_2.webp",
        "bg_wb_9_3.webp",
        "bg_wb_9_4.webp",
        "bg_wb_9_5.webp",
        "bg_wb_9_6.webp"
      ],
      "age": "3-8",
      "series": "karakulya",
      "print_length": 200,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8396895386",
      "weight": 21.6,
      "dimensions": "8.5 x 0.46 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 24.99,
            "ua": 365
          },
          "sellers": {
            "amazon": "https://a.co/d/4tuy9NC",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 24.99,
            "ua": 383
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec966",
      "title": "pages.workbooks.items.10.title",
      "description": "pages.workbooks.items.10.description",
      "slug": "karakulya_letters_tracing_and_practice_english_alphabet",
      "category": "karakulya",
      "thumbnail": "bg_wb_10_0.webp",
      "gallery": [
        "bg_wb_10_1.webp",
        "bg_wb_10_2.webp",
        "bg_wb_10_3.webp",
        "bg_wb_10_4.webp",
        "bg_wb_10_5.webp",
        "bg_wb_10_6.webp"
      ],
      "age": "3-8",
      "series": "karakulya",
      "print_length": 200,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "",
      "weight": 21.6,
      "dimensions": "8.5 x 0.46 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 24.99,
            "ua": 365
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 24.99,
            "ua": 383
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec967",
      "title": "pages.workbooks.items.11.title",
      "description": "pages.workbooks.items.11.description",
      "slug": "klyaksa_maze",
      "category": "klyaksa",
      "thumbnail": "bg_wb_11_0.webp",
      "gallery": [
        "bg_wb_11_1.webp",
        "bg_wb_11_2.webp",
        "bg_wb_11_3.webp",
        "bg_wb_11_4.webp",
        "bg_wb_11_5.webp",
        "bg_wb_11_6.webp"
      ],
      "age": "4-8",
      "series": "klyaksa",
      "print_length": 24,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8398940671",
      "weight": 4,
      "dimensions": "8.5 x 0.06 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 10.99,
            "ua": 99
          },
          "sellers": {
            "amazon": "https://a.co/d/c8qgpl0",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 10.99,
            "ua": 115
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec968",
      "title": "pages.workbooks.items.12.title",
      "description": "pages.workbooks.items.12.description",
      "slug": "klyaksa_coloring",
      "category": "klyaksa",
      "thumbnail": "bg_wb_12_0.webp",
      "gallery": [
        "bg_wb_12_1.webp",
        "bg_wb_12_2.webp",
        "bg_wb_12_3.webp",
        "bg_wb_12_4.webp",
        "bg_wb_12_5.webp",
        "bg_wb_12_6.webp"
      ],
      "age": "4-8",
      "series": "klyaksa",
      "print_length": 24,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8398943085",
      "weight": 4,
      "dimensions": "8.5 x 0.06 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 10.99,
            "ua": 99
          },
          "sellers": {
            "amazon": "https://a.co/d/aur3Sig",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 10.99,
            "ua": 115
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec969",
      "title": "pages.workbooks.items.13.title",
      "description": "pages.workbooks.items.13.description",
      "slug": "klyaksa_memory",
      "category": "klyaksa",
      "thumbnail": "bg_wb_13_0.webp",
      "gallery": [
        "bg_wb_13_1.webp",
        "bg_wb_13_2.webp",
        "bg_wb_13_3.webp",
        "bg_wb_13_4.webp",
        "bg_wb_13_5.webp",
        "bg_wb_13_6.webp"
      ],
      "age": "4-8",
      "series": "klyaksa",
      "print_length": 24,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8398945218",
      "weight": 4,
      "dimensions": "8.5 x 0.06 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 10.99,
            "ua": 99
          },
          "sellers": {
            "amazon": "https://a.co/d/j153lff",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 10.99,
            "ua": 115
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec96a",
      "title": "pages.workbooks.items.14.title",
      "description": "pages.workbooks.items.14.description",
      "slug": "klyaksa_logic",
      "category": "klyaksa",
      "thumbnail": "bg_wb_14_0.webp",
      "gallery": [
        "bg_wb_14_1.webp",
        "bg_wb_14_2.webp",
        "bg_wb_14_3.webp",
        "bg_wb_14_4.webp",
        "bg_wb_14_5.webp",
        "bg_wb_14_6.webp"
      ],
      "age": "4-8",
      "series": "klyaksa",
      "print_length": 24,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8398946123",
      "weight": 4,
      "dimensions": "8.5 x 0.06 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 10.99,
            "ua": 99
          },
          "sellers": {
            "amazon": "https://a.co/d/1AnHd3X",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 10.99,
            "ua": 115
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec96b",
      "title": "pages.workbooks.items.15.title",
      "description": "pages.workbooks.items.15.description",
      "slug": "kalyaka_shapes",
      "category": "kalyaka",
      "thumbnail": "bg_wb_15_0.webp",
      "gallery": [
        "bg_wb_15_1.webp",
        "bg_wb_15_2.webp",
        "bg_wb_15_3.webp",
        "bg_wb_15_4.webp",
        "bg_wb_15_5.webp",
        "bg_wb_15_6.webp"
      ],
      "age": "4-8",
      "series": "kalyaka",
      "print_length": 24,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8398947793",
      "weight": 4,
      "dimensions": "8.5 x 0.06 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 10.99,
            "ua": 99
          },
          "sellers": {
            "amazon": "https://a.co/d/hCjCR47",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 10.99,
            "ua": 115
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec96c",
      "title": "pages.workbooks.items.16.title",
      "description": "pages.workbooks.items.16.description",
      "slug": "kalyaka_objects",
      "category": "kalyaka",
      "thumbnail": "bg_wb_16_0.webp",
      "gallery": [
        "bg_wb_16_1.webp",
        "bg_wb_16_2.webp",
        "bg_wb_16_3.webp",
        "bg_wb_16_4.webp",
        "bg_wb_16_5.webp",
        "bg_wb_16_6.webp"
      ],
      "age": "4-8",
      "series": "kalyaka",
      "print_length": 24,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8398948714",
      "weight": 4,
      "dimensions": "8.5 x 0.06 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 10.99,
            "ua": 99
          },
          "sellers": {
            "amazon": "https://a.co/d/5XCYYku",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 10.99,
            "ua": 115
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec96d",
      "title": "pages.workbooks.items.17.title",
      "description": "pages.workbooks.items.17.description",
      "slug": "kalyaka_attention",
      "category": "kalyaka",
      "thumbnail": "bg_wb_17_0.webp",
      "gallery": [
        "bg_wb_17_1.webp",
        "bg_wb_17_2.webp",
        "bg_wb_17_3.webp",
        "bg_wb_17_4.webp",
        "bg_wb_17_5.webp",
        "bg_wb_17_6.webp"
      ],
      "age": "4-8",
      "series": "kalyaka",
      "print_length": 24,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8398949261",
      "weight": 4,
      "dimensions": "8.5 x 0.06 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 10.99,
            "ua": 99
          },
          "sellers": {
            "amazon": "https://a.co/d/236eAW5",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 10.99,
            "ua": 115
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    },
    {
      "_id": "64c20621f0ad9733e4bec96e",
      "title": "pages.workbooks.items.18.title",
      "description": "pages.workbooks.items.18.description",
      "slug": "kalyaka_puzzle",
      "category": "kalyaka",
      "thumbnail": "bg_wb_18_0.webp",
      "gallery": [
        "bg_wb_18_1.webp",
        "bg_wb_18_2.webp",
        "bg_wb_18_3.webp",
        "bg_wb_18_4.webp",
        "bg_wb_18_5.webp",
        "bg_wb_18_6.webp"
      ],
      "age": "4-8",
      "series": "kalyaka",
      "print_length": 24,
      "pub_date": "2023-07-27T05:13:40.130Z",
      "isbn": "979-8398950625",
      "weight": 4,
      "dimensions": "8.5 x 0.06 x 11 inches",
      "languages": "en",
      "color": true,
      "variations": [
        {
          "type": "paperback",
          "name": "pages.workbooks.variations.paperback",
          "costs": {
            "usd": 10.99,
            "ua": 99
          },
          "sellers": {
            "amazon": "https://a.co/d/2BxxSYI",
            "rozetka": ""
          }
        },
        {
          "type": "spiral-bound",
          "name": "pages.workbooks.variations.spiral-bound",
          "costs": {
            "usd": 10.99,
            "ua": 115
          },
          "sellers": {
            "amazon": "",
            "rozetka": ""
          }
        }
      ]
    }
  ],
  reviews:[]
};

@State<WorkbooksModel>({
  name: 'WKL_WORKBOOKS',
  defaults: _WorkbooksDefault,
})
@Injectable()
export class WorkbooksState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private store: Store, private workbooksService: WorkbooksService) {}

  ngxsOnInit(ctx?: StateContext<WorkbooksModel>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<WorkbooksModel>): void {}
  ngxsAfterBootstrap(ctx: StateContext<WorkbooksModel>) {
    ctx.dispatch(new InitWorkbooksItems());
  }

  @Selector()
  static selectWorkbooks( state: WorkbooksModel) {
    return state.items;
  }
  @Selector()
  static selectWorkbooksReview( state: WorkbooksModel) {
    return state.reviews;
  }


  static selectWorkbookBySlug( _slug: string ) {
    return createSelector([ WorkbooksState.selectWorkbooks ], ( _workbooks: WorkbookModel[]) => {
      return _workbooks.find(x => x.slug === _slug);
    });
  }
  static selectReviewsByWorkbookId( _id: string ) {
    return createSelector([ WorkbooksState.selectWorkbooksReview ], ( _reviews: ReviewModel[]) => {
      return _reviews.filter(x => x.product_id === _id);
    });
  }


  @Action(InitWorkbooksItems)
  async initWorkbooksItems(ctx: StateContext<WorkbooksModel>) {
    const _state = ctx.getState();
    let _workbooks = await this.workbooksService.getWorkbooks();
    ctx.patchState({
      ..._state,
      items: _workbooks
    });
    ctx.dispatch(new InitWorkbooksReviews());
  }

  @Action(InitWorkbooksReviews)
  public initWorkbooksReviews(ctx: StateContext<WorkbooksModel>) {
    const _state = ctx.getState();
    const _reviews = _REVIEWS;
    ctx.patchState({
      ..._state,
      reviews: _reviews
    });
  }

}
