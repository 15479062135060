import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {WorksheetsModel} from "../_models/worksheets.model";
import {InitWorksheetsCategories, InitWorksheetsItems} from "../_actions/worksheets.actions";


export const _WorksheetsDefault: WorksheetsModel = {
  categories: [],
  items: [],
};

@State<WorksheetsModel>({
  name: 'WKL_WORKSHEETS',
  defaults: _WorksheetsDefault,
})
@Injectable()
export class WorksheetsState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor() {}

  ngxsAfterBootstrap(ctx: StateContext<any>): void {
    ctx.dispatch(new InitWorksheetsCategories());
    ctx.dispatch(new InitWorksheetsItems());
  }

  ngxsOnInit(ctx?: StateContext<any>): any {

  }
  ngxsOnChanges(change: NgxsSimpleChange<WorksheetsModel>): void {}


  @Selector()
  static selectWorksheets( state: WorksheetsModel) {
    return state.items;
  }

  @Selector()
  static selectCategories( state: WorksheetsModel) {
    return state.categories;
  }


  @Action(InitWorksheetsCategories)
  public initWorksheetsCategories(ctx: StateContext<WorksheetsModel>) {
    const _state = ctx.getState();
    const _categories =  [
      {
        _id: null,
        title: 'pages.worksheet-generators.categories.math.title',
        description: 'pages.worksheet-generators.categories.math.description',
        thumbnail: 'bg_wg_1.svg',
        slug: 'math'

      },
      {
        _id: null,
        title: 'pages.worksheet-generators.categories.reading.title',
        description: 'pages.worksheet-generators.categories.reading.description',
        thumbnail: 'bg_wg_0.svg',
        slug: 'reading'

      },
      {
        _id: null,
        title: 'pages.worksheet-generators.categories.tracing.title',
        description: 'pages.worksheet-generators.categories.tracing.description',
        thumbnail: 'bg_wg_2.svg',
        slug: 'tracing'

      },
      {
        _id: null,
        title: 'pages.worksheet-generators.categories.logic.title',
        description: 'pages.worksheet-generators.categories.logic.description',
        thumbnail: 'bg_wg_3.svg',
        slug: 'logic'

      },
    ];
    ctx.patchState({
      ..._state,
      categories: _categories
    });
  }

  @Action(InitWorksheetsItems)
  public initWorksheetsItems(ctx: StateContext<WorksheetsModel>) {
    const _state = ctx.getState();
    const _items = [
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.reading.word-search.title',
        description: 'pages.worksheet-generators.items.reading.word-search.description',
        thumbnail: 'bg_wg_0_0.svg',
        slug: 'word-search',
        category: 'reading'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.reading.syllable-replacement.title',
        description: 'pages.worksheet-generators.items.reading.syllable-replacement.description',
        thumbnail: 'bg_wg_0_1.svg',
        slug: 'syllable-replacement',
        category: 'reading'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.reading.word-composition.title',
        description: 'pages.worksheet-generators.items.reading.word-composition.description',
        thumbnail: 'bg_wg_0_2.svg',
        slug: 'word-composition',
        category: 'reading'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.reading.word-matching.title',
        description: 'pages.worksheet-generators.items.reading.word-matching.description',
        thumbnail: 'bg_wg_0_3.svg',
        slug: 'word-matching',
        category: 'reading'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.reading.sound-substitution.title',
        description: 'pages.worksheet-generators.items.reading.sound-substitution.description',
        thumbnail: 'bg_wg_0_4.svg',
        slug: 'sound-substitution',
        category: 'reading'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.reading.sentences.title',
        description: 'pages.worksheet-generators.items.reading.sentences.description',
        thumbnail: 'bg_wg_0_5.svg',
        slug: 'sentences',
        category: 'reading'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.reading.letter-placement.title',
        description: 'pages.worksheet-generators.items.reading.letter-placement.description',
        thumbnail: 'bg_wg_0_6.svg',
        slug: 'letter-placement',
        category: 'reading'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.reading.word-segmentation.title',
        description: 'pages.worksheet-generators.items.reading.word-segmentation.description',
        thumbnail: 'bg_wg_0_7.svg',
        slug: 'word-segmentation',
        category: 'reading'
      },

      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.math.addition.title',
        description: 'pages.worksheet-generators.items.math.addition.description',
        thumbnail: 'bg_wg_1_0.svg',
        slug: 'addition',
        category: 'math'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.math.subtraction.title',
        description: 'pages.worksheet-generators.items.math.subtraction.description',
        thumbnail: 'bg_wg_1_1.svg',
        slug: 'subtraction',
        category: 'math'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.math.multiplication.title',
        description: 'pages.worksheet-generators.items.math.multiplication.description',
        thumbnail: 'bg_wg_1_2.svg',
        slug: 'multiplication',
        category: 'math'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.math.division.title',
        description: 'pages.worksheet-generators.items.math.division.description',
        thumbnail: 'bg_wg_1_3.svg',
        slug: 'division',
        category: 'math'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.math.measurement.title',
        description: 'pages.worksheet-generators.items.math.measurement.description',
        thumbnail: 'bg_wg_1_4.svg',
        slug: 'measurement',
        category: 'math'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.math.time.title',
        description: 'pages.worksheet-generators.items.math.time.description',
        thumbnail: 'bg_wg_1_5.svg',
        slug: 'time',
        category: 'math'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.math.comparison.title',
        description: 'pages.worksheet-generators.items.math.comparison.description',
        thumbnail: 'bg_wg_1_6.svg',
        slug: 'comparison',
        category: 'math'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.math.problems.title',
        description: 'pages.worksheet-generators.items.math.problems.description',
        thumbnail: 'bg_wg_1_7.svg',
        slug: 'problems',
        category: 'math'
      },

      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.tracing.letters.title',
        description: 'pages.worksheet-generators.items.tracing.letters.description',
        thumbnail: 'bg_wg_2_0.svg',
        slug: 'letters',
        category: 'tracing'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.tracing.roman-numerals.title',
        description: 'pages.worksheet-generators.items.tracing.roman-numerals.description',
        thumbnail: 'bg_wg_2_1.svg',
        slug: 'roman-numerals',
        category: 'tracing'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.tracing.arabic-numerals.title',
        description: 'pages.worksheet-generators.items.tracing.arabic-numerals.description',
        thumbnail: 'bg_wg_2_2.svg',
        slug: 'arabic-numerals',
        category: 'tracing'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.tracing.words.title',
        description: 'pages.worksheet-generators.items.tracing.words.description',
        thumbnail: 'bg_wg_2_3.svg',
        slug: 'words',
        category: 'tracing'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.tracing.syllables.title',
        description: 'pages.worksheet-generators.items.tracing.syllables.description',
        thumbnail: 'bg_wg_2_4.svg',
        slug: 'syllables',
        category: 'tracing'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.tracing.objects.title',
        description: 'pages.worksheet-generators.items.tracing.objects.description',
        thumbnail: 'bg_wg_2_5.svg',
        slug: 'objects',
        category: 'tracing'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.tracing.shapes.title',
        description: 'pages.worksheet-generators.items.tracing.shapes.description',
        thumbnail: 'bg_wg_2_6.svg',
        slug: 'shapes',
        category: 'tracing'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.tracing.coloring-pages.title',
        description: 'pages.worksheet-generators.items.tracing.coloring-pages.description',
        thumbnail: 'bg_wg_2_7.svg',
        slug: 'coloring-pages',
        category: 'tracing'
      },

      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.logic.find-the-way.title',
        description: 'pages.worksheet-generators.items.logic.find-the-way.description',
        thumbnail: 'bg_wg_3_0.svg',
        slug: 'find-the-way',
        category: 'logic'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.logic.whats-wrong.title',
        description: 'pages.worksheet-generators.items.logic.whats-wrong.description',
        thumbnail: 'bg_wg_3_1.svg',
        slug: 'whats-wrong',
        category: 'logic'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.logic.identify-shapes.title',
        description: 'pages.worksheet-generators.items.logic.identify-shapes.description',
        thumbnail: 'bg_wg_3_2.svg',
        slug: 'identify-shapes',
        category: 'logic'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.logic.find-the-pair.title',
        description: 'pages.worksheet-generators.items.logic.find-the-pair.description',
        thumbnail: 'bg_wg_3_3.svg',
        slug: 'find-the-pair',
        category: 'logic'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.logic.puzzles.title',
        description: 'pages.worksheet-generators.items.logic.puzzles.description',
        thumbnail: 'bg_wg_3_4.svg',
        slug: 'puzzles',
        category: 'logic'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.logic.patterns.title',
        description: 'pages.worksheet-generators.items.logic.patterns.description',
        thumbnail: 'bg_wg_3_5.svg',
        slug: 'patterns',
        category: 'logic'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.logic.find-the-shadow.title',
        description: 'pages.worksheet-generators.items.logic.find-the-shadow.description',
        thumbnail: 'bg_wg_3_6.svg',
        slug: 'find-the-shadow',
        category: 'logic'
      },
      {
        _id: null,
        active:true,
        title: 'pages.worksheet-generators.items.logic.logical-connections.title',
        description: 'pages.worksheet-generators.items.logic.logical-connections.description',
        thumbnail: 'bg_wg_3_7.svg',
        slug: 'logical-connections',
        category: 'logic'
      }

    ];
    ctx.patchState({
      ..._state,
      items: _items
    });
  }

}
