export const environment = {
  baseUrl: 'https://whizki.com',
  api_endpoint: 'https://whizki.com',
  production: true,
  storeDevtools: false,
  hmr: false,
  apiUrl: '',
  default_language: 'en'
};

